import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = [
    "game",
    "click",
    "loanTaken",
    "loanRepaid",
    "endRound",
    "penalised",
    "mitigated",
    "acquiredCustomer",
    "recovered",
    "gameOver",
    "victory",
  ];

  targetPlaySound(target) {
    new Promise(() => {
      let sound = target;
      if (!window.localStorage.getItem("soundsMuted")) {
        sound.play();
      }
      sound.remove();
    });
  }

  connect() {
    this.click = this.click.bind(this);
    document.addEventListener("click", this.click, true);
  }

  disconnect() {
    document.removeEventListener("click", this.click, true);
  }

  penalisedTargetConnected() {
    this.targetPlaySound(this.penalisedTarget.cloneNode());
  }

  mitigatedTargetConnected() {
    this.targetPlaySound(this.mitigatedTarget.cloneNode());
  }

  acquiredCustomerTargetConnected() {
    this.targetPlaySound(this.acquiredCustomerTarget.cloneNode());
  }

  recoveredTargetConnected() {
    this.targetPlaySound(this.recoveredTarget.cloneNode());
  }

  gameOverTargetConnected(element) {
    this.targetPlaySound(this.gameOverTarget.cloneNode());
  }

  victoryTargetConnected() {
    this.targetPlaySound(this.victoryTarget.cloneNode());
  }

  click(event) {
    const insideGame = this.gameTarget.contains(event.target);
    const isClickableElement = ["BUTTON", "A"].some(
      (tag) => event.target.tagName === tag
    );

    const disabled = event.target.getAttribute("disabled");
    if (!insideGame || !isClickableElement || disabled) return;

    this.targetPlaySound(this.clickTarget.cloneNode());

    const commandTypeValue = event.target.dataset.commandTypeValue;
    // console.log(commandTypeValue);

    const isUndefinedCommand = commandTypeValue === undefined ? true : false;
    if (isUndefinedCommand) return;

    const commandSoundTarget =
      {
        "Command::TakeLoan::V0": this.loanTakenTarget.cloneNode(),
        "Command::RepayLoan::V0": this.loanRepaidTarget.cloneNode(),
        "Command::DrawCyberAttack::V0": this.endRoundTarget.cloneNode(),
      }[commandTypeValue] ?? null;

    if (commandSoundTarget != null) {
      this.targetPlaySound(commandSoundTarget);
    }
  }
}
